'use strict';
var storeLocator = require('../store-locator');

exports.init = function () {
    var locator = window.Scripts.storeLocator;
    var options = locator.vars;
    var marker = options.markerurl;

    if ($('#storedetails-wrapper').length > 0) {
        marker = options.markerdetailurl;
    }

    storeLocator.init(options.zoomradius, options.storeurl, marker, options.queryurl, options.cookieurl, options.cookiename, options.defaultlocation, options.maptype);

    if ($('.store-tabs').length) {
        $(".stores-tab").click(function () {
            var linkTab = $(this).attr('id');
            linkTab = "." + linkTab + "-content";
            // first remove the active class from all the tabs
            $('.stores-tab').removeClass('active');
            // then apply active class on the target
            $(this).addClass('active');
            $('#store-locator .tab-view').removeClass('show');
            $(linkTab).addClass('show');
        });
    }
};
